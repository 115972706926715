/**
 * Created by vaibhav on 2/4/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Link from "gatsby-link"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import NoSSR from "react-no-ssr"
import BackgroundSlider from "react-background-slider"
import PageWrapper from "../../layouts/PageWrapper"
import PortImg1 from "../../img/port/img1.png"
import PortImg2 from "../../img/port/img2.png"
import PortImg3 from "../../img/port/img3.png"
import HomeLogo from "../../components/HomeLogo"

class CorePage extends Component {
  componentDidMount() {
    window.onpageshow = function () {
      const slider = document.getElementById("ReactBackgroundSlider")
      if (slider) {
        slider.style.height = `${
          document.getElementById("master").scrollHeight
        }px`
        slider.style.width = "100vw"
        slider.style.position = "absolute"
      }
    }
    setTimeout(function () {
      const slider = document.getElementById("ReactBackgroundSlider")
      if (slider) {
        slider.style.height = `${
          document.getElementById("master").scrollHeight
        }px`
        slider.style.width = "100vw"
        slider.style.position = "absolute"
      }
    }, 500)
  }

  render() {
    return (
      <PageWrapper>
        <section className="hero is-fullheight" id="master">
          <div>
            <div id="core_slider-desktop">
              <NoSSR>
                <BackgroundSlider
                  images={[PortImg1, PortImg2, PortImg3]}
                  duration={5}
                  transition={2}
                />
              </NoSSR>
            </div>
            <Helmet>
              <title>Core Businesses - E.A. Technique</title>
            </Helmet>
            <section className="hero is-small">
              <div className="hero-body">
                <div className="container">
                  <div className="columns">
                    <div className="column">
                      <HomeLogo />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="section is-paddingless" id="core_page">
              <div id="core_slider-mobile">
                <NoSSR>
                  <BackgroundSlider
                    images={[PortImg1, PortImg2, PortImg3]}
                    duration={5}
                    transition={2}
                  />
                </NoSSR>
              </div>
              <div className="container">
                <Tabs defaultIndex={2}>
                  <div className="core columns is-multiline">
                    <div className="column is-6-desktop is-12-tablet">
                      <div
                        className="box"
                        id="core-box"
                        style={{
                          opacity: 0.9,
                        }}
                      >
                        <TabPanel />
                        <TabPanel />
                        <TabPanel>
                          <h3 className="title is-primary is-3">
                            Provision of Port Marine Services
                          </h3>
                          <p style={{ textAlign: "justify", fontSize: "14px" }}>
                           We are also engaged in the provision of port marine services for petrochemical and bulk & containerized ports in Malaysia.
                            <br />
                            <br />
                            The types of port marine services that we provide at the ports include, among others:
                            <br />
                            <br />  
                            •Towage services comprising towing, pushing or maneuvering vessels; and
                            <br />
                            <br />
                            •Mooring services which involve securing a marine vessel to specially constructed fixtures
                            such as piers, quays, wharfs, jetties, anchor buoys and mooring buoys.
                            <br />
                            <br />
                           We also provide dockside mooring services where we have mooring personnel to secure vessels to floating structures and fixtures at the wharf.
                          </p>
                        </TabPanel>
                        <TabPanel />
                        <TabPanel />
                      </div>
                    </div>
                    <div className="column is-3-desktop is-9-tablet is-offset-3 core_page-tabs-container">
                      <TabList className="react-tabs__tab-list-primary core_page-tabs-list orange">
                        <Link to="/core">
                          <Tab>
                            Marine Transportation and Offshore Storage of Oil &
                            Gas
                          </Tab>
                        </Link>
                        <Link to="/core/engineer">
                          <Tab>Marine Engineering Service</Tab>
                        </Link>
                        <Link to="/core/port">
                          <Tab>Provision of Port Marine Services</Tab>
                        </Link>
                      </TabList>
                    </div>
                  </div>
                </Tabs>
              </div>
            </section>
          </div>
        </section>
      </PageWrapper>
    )
  }
}

CorePage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default CorePage
